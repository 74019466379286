import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import ApiService from "@/core/services/ApiService";
import "bootstrap";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import i18n from "@/core/plugins/i18n";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { BaseService } from "@/core/services/BaseService";
import permission from "@/core/directives/permission";
import 'vue-toast-notification/dist/theme-sugar.css';
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from 'vue-toast-notification';

const app = createApp(App)
BaseService._axios.interceptors.response.use((response) => {
  if (response.status === 201 || response.status === 202) {
    console.log("status ", response.status )
    useToast().success('Save successfully', {position: 'top-right'})
  }
  return response
}, error => {
  const response = error.response;
  if (response.status === 422) {
    store.dispatch(Actions.CREATE_FORM_ERROR, response.data.errors)
  }
  return Promise.reject(error.response)
})
initInlineSvg(app);


const clickOutside = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = event => {
      if (event.target.dataset.target === 'head-top') return;
      if (!el.contains(event.target)) {
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

app.directive("has-access", permission)
app.directive("click-outside", clickOutside)
app.use(router)
app.use(VueSweetalert2);
app.use(store)
app.use(ElementPlus);
app.use(i18n)

ApiService.init(app);
app.mount('#app')
