import axios, { AxiosInstance } from "axios";
import JwtService from "@/core/services/JwtService";
import { IFilter } from "@/core/entity/IFilter";
import { generateSearchString, generateString, generatorQueryString } from "@/core/helpers/functions";
import { ISearchFilter } from "@/core/entity/ISearchFilter";

export abstract class BaseService<T> {
  static _axios: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URI
  })
  static _axios2 : AxiosInstance =  axios.create({
    baseURL: process.env.VUE_APP_API_URI
  })
  public uploadHttp() : AxiosInstance {
    BaseService._axios2.defaults.headers.common[
      "Authorization"
      ] = `${JwtService.getToken()}`;
    return BaseService._axios2;
  }

  protected http(): AxiosInstance {
    BaseService._axios.defaults.headers.common[
      "Authorization"
      ] = `${JwtService.getToken()}`;
    BaseService._axios.defaults.headers.common["Accept"] =
      "application/json";
    return BaseService._axios;
  }

  protected
  abstract endpoint: string;

  public async search<K>(filter: ISearchFilter): Promise<K> {
    const queryString = generateSearchString(filter);
    console.log(queryString);
    return this.http().get("/search?" + queryString).then(res => {
      return res.data
    })
  }

  public async get<T>(slug: string): Promise<T> {
    return this.http().get<T>(this.endpoint + "/" + slug).then(res => {
      return res.data
    })
  }

  public async findAll<K>(filter: any): Promise<K> {
    const queryString = generatorQueryString(filter);
    return this.http().get<K>(this.endpoint + "?" + queryString).then(res => {
      return res.data
    })
  }

  public async listFilter<K>(filter: any): Promise<K> {
    const queryString = generateString(filter);
    return this.http().get<K>(this.endpoint + "?" + queryString).then(res => {
      return res.data
    })
  }

  public async create<T>(data: any): Promise<T> {
    return this.http().post<T>(this.endpoint, data).then(res => {
      return res.data
    })
  }

  public async update<T>(id: string, data: any): Promise<T> {
    return this.http().put<T>(this.endpoint + "/" + id, data).then(res => {
      return res.data
    })
  }

  public async delete(id: string): Promise<any> {
    return this.http().delete(this.endpoint + "/" + id).then(res => {
      return res.data
    })
  }

}