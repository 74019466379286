import { useCookies } from 'vue3-cookies'

const {cookies} = useCookies();

const ID_TOKEN_KEY = "TR_TOKEN_ID" as string;
const RESET_PASSWORD = "RESET_PASSWORD" as string;
const ROLES = "ROLES" as string;
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return cookies.get(ID_TOKEN_KEY);
};

export const isAdmin = (): boolean => {
  const roles = cookies.get(ROLES)
  if (!roles) return false
  return roles.includes("ROLE_ADMIN")
}

export const isEditor = (): boolean => {
  const roles = cookies.get(ROLES)
  if (!roles) return false
  return roles.includes("ROLE_EDITOR")
}
export const getResetPassword = (): boolean => {
  return cookies.get(RESET_PASSWORD) === 'true'
}
/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  cookies.set(ID_TOKEN_KEY, token)
};

/**
 * save roles
 * @param roles
 */
export const saveRoles = (roles: any): void => {
  cookies.set(ROLES, roles);
}


export const saveResetPassword = (resetPassword: any): void => {
  cookies.set(RESET_PASSWORD, resetPassword)
};
/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  cookies.remove(ID_TOKEN_KEY)
  cookies.remove(ROLES)
};

export default {getToken, saveToken, destroyToken, getResetPassword, saveResetPassword, saveRoles, isAdmin, isEditor};
