enum Actions {
  // action types


  LOAD_FILES = 'LOAD_FILES',
  LOAD_SECTIONS = 'LOAD_SECTIONS',
  LOAD_MENU ='LOAD_MENU',
  LOAD_PAGES = 'LOAD_PAGES',
  LOAD_PAGE = 'LOAD_PAGE',
  LOAD_SUB_MENU = 'LOAD_SUB_MENU',
  LOAD_COLLECTIONS = 'LOAD_COLLECTIONS',
  ADD_PAGE = 'ADD_PAGE',
  LOAD_ROLES = 'loadRoles',
  LOAD_USER_ROLES = 'loadUserRoles',
  LOAD_LOGIN_HISTORY = 'loadLoginHistory',
  CREATE_FORM_ERROR = 'createFormError',
  LOAD_CLIENTS = 'loadClient',
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  VERIFY_USER = 'verifyUser',
  LOGOUT = "logout",
  REGISTER = "register",
  LOAD_PROFILE = "loadProfile",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_BREADCRUMB_ACTION_LINK = "setBreadcrumbActionLink",
  LOAD_COUNTRY = 'loadCountry',
  LOAD_STATE = 'loadState',
  LOAD_USERS = 'loadUsers',
  LOAD_PERMISSIONS = 'loadPermission',
  LOAD_USER = "loadUser",
}


enum Mutations {
  // mutation types
  REMOVE_SELECTED_SECTION= 'REMOVE_SELECTED_SECTION',
  SET_FILES = 'SET_FILES',
  SET_SELECTED_DOC = 'SET_SELECTED_DOC',
  SET_SECTIONS = 'SET_SECTIONS',
  SET_COLLECTIONS = 'SET_COLLECTIONS',
  DELETE_PAGE_ITEM = 'DELETE_PAGE_ITEM',
  SET_PAGE_TO_LIST = 'SET_PAGE_TO_LIST',
  SET_PAGES = 'SET_PAGES',
  SET_PAGE_ENTITY = 'SET_PAGE_ENTITY',
  SET_PAGE_ITEMS = 'SET_PAGE_ITEMS',
  SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE',
  SET_ACTIVE_MENU = 'SET_ACTIVE_MENU',
  SET_PAGE = 'SET_PAGE',
  SET_MENU = 'SET_MENU',
  SET_ROLES = 'setRoles',
  SET_USER_ROLES = 'setUserRoles',
  SET_LOGIN_AUDITS = 'setLoginAudits',
  SET_TEMPLATE = 'setTemplate',
  SET_TEMPLATES = 'setTemplates',
  SET_COMPANY = 'setCompany',
  SET_LOGIN_HISTORY = 'setLoginHistory',
  SET_FORM_ERROR = 'setFormError',
  SET_COMPANIES = 'setCompanies',
  SET_CLIENTS = 'setClient',
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_VERIFY_CODE = 'setVerifyCode',
  SET_AUTH = "setAuth",
  SET_PROFILE = "setProfile",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_BREADCRUMB_MUTATION_LINK = "setBreadcrumbMutationLink",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_LOCATION_COUNTRY = 'setLocationCountry',
  SET_LOCATION_STATE = 'setLocationState',
  // users
  SET_USERS = 'setUsers',
  SET_USER = 'setUser',
  //product
  SET_PRODUCTS = 'setProducts',
  SET_PRODUCT = 'setProduct',
  SET_PERMISSIONS = 'setPermissions',
}

export { Actions, Mutations };
