import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/doc/:id",
        name: "pageDetail",
        component: () => import("@/views/page/Page.vue"),
        props: route => ({id: route.params.id})
      },
      {
        path: "/doc/:id/:section",
        name: "sectionDetails",
        component: () => import("@/views/page/Page.vue"),
        props: route => ({id: route.params.id, section: route.params.section})
      },
      {
        path: "/doc/collection/:id",
        name: "collectionDetail",
        component: () => import("@/views/page/Collection.vue"),
        props: route => ({id: route.params.id})
      },
      {
        path: "/doc/folder/:id",
        name: "folder",
        component: () => import("@/views/page/Collection.vue"),
        props: route => ({id: route.params.id})
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/layout/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/auth/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/Error403.vue"),
  },
  {

    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  // reset config to initial state
  const resetPage = ['/profile/change-password'];
  const resetRequired = !resetPage.includes(to.path);
  if (resetRequired) {
    if (JwtService.getResetPassword()) {
      return next({
        name: 'changePassword',
      });
    }
  }
  store.dispatch(Actions.VERIFY_AUTH);
  next()
});
export default router;