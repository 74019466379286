export const ENDPOINT = {
  LOGIN: '/user/login',
  VERIFY: '/user/verify',
  PROFILE: "/user/me",
  PRODUCT: "/product",
  POLICY: "/policy",
  CASE: "/case",
  LOCATION: "/location",
  COMPANY: '/company',
  USER: "/user",
  ACTIVITY: "/activity",
  NOTE: "/note",
  LOGIN_HISTORY: "/login-history",
  DOCUMENT: '/document'
}
export const OPERATOR = {
  EQ: 'eq', // =
  LIKE: 'lk', // like
  IN: 'in',  // in
  GT: 'gt', // >
  LS: 'ls', // <
  GREQ: 'gte', // >-
  LSEQ: 'lte', // <=
  NE: 'ne', // !=
}

export const SEARCH_ENTITY = {
  POLICY: 'policy',
  POLICY_VIEW: 'pv',
  CASE_VIEW: 'cv',
  CASE: 'case',
  ACTIVITY: "activity",
  USER: "user",

}


export const PRODUCT_TYPE = [
  {name: 'Group', value: 'GROUP'},
  {name: 'Travel', value: 'TRAVEL'},
]


export const TEMPLATE_TYPE = [
  {name: 'Note', value: 'NOTE'},
  {name: 'Script', value: 'SCRIPT'},
]
export const CASE_TYPE = [
  {name: 'In-patient', value: 'IP'},
  {name: 'Out-patient', value: 'OP'},
  {name: 'Other', value: 'OT'},
]

export const ACTIVITY_TYPE = [
  {name: 'Phone Call (IN)', value: 'CALL_IN'},
  {name: 'Phone Call (OUT)', value: 'CALL_OUT'},
  {name: 'Reserve Update', value: 'RESERVE'},
  {name: 'Inquiry Status', value: 'INQ_ST'},
  {name: 'Medical Review', value: 'CMR'},
  {name: 'Case Review', value: 'CR'},
]
export const ACTIVITY_STATUS = [
  {name: 'Completed', value: 'DONE'},
  {name: 'Active', value: 'ACTIVE'},
  {name: 'Pending', value: 'PENDING'},
  {name: 'Not Required', value: 'CANCELED'},
]

export const CURRENCY = [
  'CAD', 'USD', 'EUR', 'GBP', 'MXN', 'CUP', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTC', 'BTN', 'BWP', 'BYN', 'BYR', 'BZD', 'CDF', 'CHF', 'CLF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EEK', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GGP', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'IMP', 'INR', 'IQD', 'IRR', 'ISK', 'JEP', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LTL', 'LVL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XCD', 'XDR', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZMW', 'ZWL', 'VES'
]