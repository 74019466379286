import { BaseService } from "@/core/services/BaseService";
import { IUserPermission } from "@/core/entity/IUserPermission";

export class PermissionService extends BaseService<any> {
  endpoint = "/permission";

  /**
   * load user permission app
   */
  async getUserPermission(): Promise<IUserPermission[]> {
    return await this.http().get<IUserPermission[]>(this.endpoint).then(res => {
      return res.data
    })
  }
}

export default new PermissionService();