import { BaseService } from "@/core/services/BaseService";
import { ENDPOINT } from "@/core/config/Constant";
import type { IFilter } from "@/core/entity/IFilter";
import { generatorQueryString } from "@/core/helpers/functions";

class UserService extends BaseService<any> {
  endpoint = ENDPOINT.USER;

  async loginHistory(id: string, filter: IFilter) {
    const queryString = generatorQueryString(filter);
    return await this.http().get(this.endpoint + "/" + id + "/login-history?" + queryString).then(res => {
      return res.data;
    })
  }


  public async updateProfile(data): Promise<any> {
    return await this.http().put(this.endpoint + "/update-profile", data).then(res => {
      return res.data;
    })
  }


  async updateStatus(id, status) {
    return await this.http().put(this.endpoint + "/" + id + "/status", {status: status}).then(res => {
      return res.data;
    })
  }

  async presetMfa(id, data) {
    return await this.http().put(this.endpoint + "/" + id + "/preset-mfa", data).then(res => {
      return res.data;
    })
  }
  async resetPassword(id) {
    return await this.http().put(this.endpoint + "/" + id + "/reset-password").then(res => {
      return res.data;
    })
  }

  public async login(data): Promise<any> {
    return this.http().post(this.endpoint + "/login", data).then(res => {
      return res.data
    })
  }

  public async verify(data): Promise<any> {
    return this.http().post(this.endpoint + "/verify", data).then(res => {
      return res.data
    })
  }

  public async changePassword(data): Promise<any> {
    return this.http().put(this.endpoint + "/change-password", data).then(res => {
      return res.data
    })
  }

  public async roles(id: string, filter: IFilter) {
    return await this.http().get(this.endpoint + "/" + id + "/roles").then(res => {
      return res.data
    })
  }

  public async addRole(id: any, model: any) {
    return await this.http().put(this.endpoint + "/" + id + "/roles", model).then(res => {
      return res.data
    })
  }
}

export default new UserService();