import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import type { ITableResponse } from "@/core/entity/ITableResponse";
import type { IUser } from "@/core/entity/IUser";
import UserService from "@/core/services/UserService";
import type { ISearchFilter } from "@/core/entity/ISearchFilter";
import RoleService from "@/core/services/RoleService";

@Module
export default class AuthModule extends VuexModule {
  userPage: ITableResponse<IUser> = {
    total: 0,
    pages: 0,
    data: []
  };
  user: any = {};

  loginHistoryPage: ITableResponse<any> = {
    total: 0,
    pages: 0,
    data: []
  }

  userRoles = [];
  roles = [];

  get getUsers() {
    return this.userPage;
  }

  get getUser() {
    return this.user
  }

  @Mutation
  [Mutations.SET_USERS](data: any) {
    this.userPage = data;
  }

  @Mutation
  [Mutations.SET_USER](data: any) {
    this.user = data;
  }

  @Mutation
  [Mutations.SET_USER_ROLES](data: any) {
    this.userRoles = data;
  }

  @Mutation
  [Mutations.SET_ROLES](data: any) {
    this.roles = data;
  }

  @Mutation
  [Mutations.SET_LOGIN_HISTORY](data: ITableResponse<any>) {
    this.loginHistoryPage = data
  }

  @Action
  [Actions.LOAD_USERS](filter: ISearchFilter) {
    UserService.search(filter).then(res => {
      this.context.commit(Mutations.SET_USERS, res);
    })
  }

  @Action
  [Actions.LOAD_LOGIN_HISTORY](loadFilter: any) {
    UserService.loginHistory(loadFilter.id, loadFilter.filter).then(res => {
      this.context.commit(Mutations.SET_LOGIN_HISTORY, res);
    })
  }

  @Action
  [Actions.LOAD_USER](id) {
    UserService.get(id).then(res => {
      this.context.commit(Mutations.SET_USER, res);
    })
  }

  @Action
  async [Actions.LOAD_USER_ROLES](loadFilter: any) {
    await UserService.roles(loadFilter.id, loadFilter.filter).then(res => {
      this.context.commit(Mutations.SET_USER_ROLES, res)
    })
  }

  @Action
  async [Actions.LOAD_ROLES]() {
    await RoleService.list().then(res => {
      this.context.commit(Mutations.SET_ROLES, res)
    })
  }
}