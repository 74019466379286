import { BaseService } from "@/core/services/BaseService";
import { ENDPOINT } from "@/core/config/Constant";
import { ILocation } from "@/core/entity/ILocation";

class LocationService extends BaseService<any> {
  endpoint = ENDPOINT.LOCATION;

  async list(type: string): Promise<ILocation[]> {
    return await this.http().get<ILocation[]>(this.endpoint + "?type=" + type).then(res => {
      return res.data
    })
  }

  async listByCode(code: string): Promise<ILocation[]> {
    return await this.http().get<ILocation[]>(this.endpoint + "/" + code).then(res => {
      return res.data
    })
  }
}

export default new LocationService()