import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import UserModule from "@/store/modules/UserModule";
import PermissionModule from "@/store/modules/PermissionModule";
import LocationModule from "@/store/modules/LocationModule";
import ValidationModule from "@/store/modules/ValidationModule";
import DocModule from "@/store/modules/DocModule";
config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserModule,
    PermissionModule,
    LocationModule,
    ValidationModule,
    DocModule,
  },
});

export default store;
