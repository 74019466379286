import { BaseService } from "@/core/services/BaseService";
import { buildFormData } from "@/core/helpers/functions";

class DocService extends BaseService<any> {
  endpoint = '/doc';

  async updateStatus(id, status) {
    return await this.http().put(this.endpoint + "/" + id + "/status?status=" + status).then(res => {
      return res.data
    })
  }

  async updateOrder(id, docId) {
    return await this.http().put(this.endpoint + "/" + id + "/order?docId=" + docId).then(res => {
      return res.data
    })
  }

  async menu() {
    return await this.http().get(this.endpoint + "/menu").then(res => {
      return res.data
    })
  }

  async sections(id) {
    return await this.http().get(this.endpoint + "/" + id + "/sections").then(res => {
      return res.data
    })
  }

  async files(id) {
    return await this.http().get(this.endpoint + "/" + id + "/files").then(res => {
      return res.data
    })
  }

  async upload(data) {
    const formData = buildFormData(data)
    return await this.uploadHttp().post(this.endpoint + "/files", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      return res.data;
    })
  }

  async download(id) {
    return await this.http().get(this.endpoint + "/files/" + id + "/download",
      {responseType: "blob"}).then(res => {
      return res;
    })
  }

}

export default new DocService();